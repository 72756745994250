body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-tiny-popover-container {
  z-index: 100000;
}

.react-flow__handle.connectionindicator:hover {
  background: #731dcf !important;
  box-shadow: rgba(239, 230, 249, 1) 0px 0px 0px 5px !important;
}

.validationflow .react-flow__handle-valid {
  background: #731dcf !important;
  box-shadow: rgba(239, 230, 249, 1) 0px 0px 0px 5px !important;
}
